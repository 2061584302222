import { createAttachedSignature, createDetachedSignature, createHash, getUserCertificates } from 'crypto-pro'
import { apolloClient } from '@/apollo.config'

export const click = async function (branch) {
  let token = sessionStorage.getItem('crptech_token')

  if (!token) {
    await getNewToken(branch)
  }

  if (token) {
    let decoded = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    if (decoded.exp * 1000 - Date.now() < 0) await getNewToken()
  }
}

export const getNewToken = async function (branch) {
  const all_tokens = JSON.parse(sessionStorage.getItem(branch))
  const { data } = await apolloClient.query({
    query: require('@/gql/queries/getSession.graphql'),
    variables: {
      branch
    }
  })
  if (data?.getSession) {
    let signed = []
    for (const item of data.getSession) {
      const certificate = (await getUserCertificates())?.find((el) => el.thumbprint === item.thumbprint)
      if (all_tokens) {
        const old = all_tokens[item.thumbprint]
        const decoded = JSON.parse(Buffer.from(old.split('.')[1], 'base64').toString())
        if (certificate && decoded?.exp * 1000 - Date.now() < 0) {
          const signature = await createSignature(certificate, item.data)
          signed.push({
            uuid: item.uuid,
            data: signature,
            thumbprint: item.thumbprint
          })
        }
      } else {
        const signature = await createSignature(certificate, item.data)
        signed.push({
          uuid: item.uuid,
          data: signature,
          thumbprint: item.thumbprint
        })
      }
    }
    if (signed.length) {
      const response = await apolloClient.mutate({
        mutation: require('@/gql/mutations/createToken.graphql'),
        variables: {
          input: signed
        }
      })

      if (response?.data?.createToken) {
        for (const token of response.data.createToken) {
          sessionStorage.setItem(
            branch,
            JSON.stringify({
              ...JSON.parse(sessionStorage.getItem(branch)),
              [token.thumbprint]: token.token
            })
          )
        }
      }
    }
  }
}
export const createSignature = async (cert, message) => {
  let thumbprint = cert.thumbprint,
    signaturePromise = createAttachedSignature(thumbprint, message)
  return await signaturePromise
}
export const createDetached = async (cert, message) => {
  const hash = await createHash(message)

  return await createDetachedSignature(cert.thumbprint, hash)
}

export const getCert = async (thumbprint) => {
  return (await getUserCertificates()).find((el) => el.thumbprint === thumbprint)
}

export const owner_info = async (cert) => {
  const owner_info = await cert.getOwnerInfo()
  let last_name = owner_info.find((el) => el.title === 'Фамилия')?.description ?? ''
  let other_name = owner_info.find((el) => el.title === 'Имя Отчество')?.description ?? ''
  return {
    inn: owner_info.find((el) => el.title === 'ИНН')?.description ?? '',
    position: owner_info.find((el) => el.title === 'Должность')?.description ?? '',
    full_name: last_name + ' ' + other_name
  }
}
