var render = function render(){
  var _vm$product, _vm$product2, _this$product, _this$original_produc;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "binding-btn",
    attrs: {
      "id": ((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.id) + 'binding-btn',
      "title": ""
    },
    on: {
      "click": _vm.click
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.icon,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "binding-tooltip"
  }, [_c('b-tooltip', {
    staticClass: "binding-tooltip",
    attrs: {
      "target": ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.id) + 'binding-btn',
      "placement": "top",
      "custom-class": "product-info-tooltip"
    }
  }, [(_this$product = this.product) !== null && _this$product !== void 0 && _this$product.hide ? _c('div', [_vm._v("Свяжите товар внешней системы")]) : _c('div', [_c('div', [_vm._v(" " + _vm._s((_this$original_produc = this.original_product) === null || _this$original_produc === void 0 ? void 0 : _this$original_produc.name) + " ")]), _c('span', {
    staticClass: "product-info"
  }, [_vm._v(_vm._s(_vm.info))])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }