var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "crptech-tab"
    }
  }, [_c('mark-modal', {
    attrs: {
      "item": _vm.itemToShow
    }
  }), _c('div', {
    staticClass: "header mt-3 mb-3"
  }, [_c('div', {
    staticClass: "header__controls"
  }, [_c('i-select', {
    model: {
      value: _vm.production,
      callback: function ($$v) {
        _vm.production = $$v;
      },
      expression: "production"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "null"
    }
  }, [_vm._v("Все типы продукции")]), _vm._l(_vm.markingTypeList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c('i-select', {
    model: {
      value: _vm.remainders,
      callback: function ($$v) {
        _vm.remainders = $$v;
      },
      expression: "remainders"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "null"
    }
  }, [_vm._v("Все остатки")]), _vm._l(_vm.remaindersList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c('i-select', {
    model: {
      value: _vm.statuses,
      callback: function ($$v) {
        _vm.statuses = $$v;
      },
      expression: "statuses"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "null"
    }
  }, [_vm._v("Все статусы")]), _vm._l(_vm.statusesList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1), _c('div', {
    staticClass: "header__right"
  }, [_c('span', [_vm._v(" Дата актуальности: " + _vm._s(_vm.get_date()) + " ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.refreshData
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/refresh.svg"
    }
  }), _vm._v(" Обновить ")])], 1)]), _c('crp-tech-tab-head', {
    on: {
      "handler_search": _vm.handlerSearch
    }
  }), _c('resizable-table', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "table_name": "crptech-diagnostics",
      "default_fields": _vm.fields,
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox')];
      },
      proxy: true
    }, {
      key: "head_hide",
      fn: function () {
        return [_c('img', {
          attrs: {
            "src": "/img/icons/connection.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-checkbox', {
          attrs: {
            "value": item.id
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "body_hide",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('binding-chain-btn', {
          attrs: {
            "spec": {},
            "type": "crp-tech",
            "original_product": item === null || item === void 0 ? void 0 : item.original_product,
            "product": item
          },
          on: {
            "click": function ($event) {
              return _vm.showBindingModal(item);
            }
          }
        })];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(data.item.name) + " "), _c('div', {
          staticClass: "b-name article"
        }, [[_c('div', {
          staticClass: "b-name article text-left"
        }, [_vm._v(" " + _vm._s(data.item.articul || '--') + " ")])]], 2)])];
      }
    }, {
      key: "body_ext_name",
      fn: function (data) {
        var _data$item$ext_produc, _data$item$ext_produc2;

        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s((_data$item$ext_produc = data.item.ext_product) === null || _data$item$ext_produc === void 0 ? void 0 : _data$item$ext_produc.name) + " "), _c('div', {
          staticClass: "b-name article"
        }, [_c('div', {
          staticClass: "b-name article text-left"
        }, [_vm._v(" " + _vm._s(((_data$item$ext_produc2 = data.item.ext_product) === null || _data$item$ext_produc2 === void 0 ? void 0 : _data$item$ext_produc2.code) || '--') + " ")])])])];
      }
    }, {
      key: "body_total",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-inline-flex align-items-center"
        }, [_c('span', [_vm._v(" " + _vm._s(item.marks - item.unhadled_marks || 0))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/docs/marking.svg"
          },
          on: {
            "click": function ($event) {
              return _vm.showMarkModal(item);
            }
          }
        })])];
      }
    }, {
      key: "body_crptech",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-inline-flex align-items-center"
        }, [_c('span', [_vm._v(_vm._s(item.marks || 0))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/docs/marking.svg"
          },
          on: {
            "click": function ($event) {
              return _vm.showMarkModal(item);
            }
          }
        })])];
      }
    }, {
      key: "body_manufacturer",
      fn: function (_ref5) {
        var _item$manufacturer, _item$manufacturer2, _item$manufacturer3, _item$manufacturer4, _item$manufacturer5, _item$manufacturer6;

        var item = _ref5.item;
        return [_c('div', {
          staticClass: "b-supplier name cursor text-left"
        }, [_vm._v(" " + _vm._s((_item$manufacturer = item.manufacturer) !== null && _item$manufacturer !== void 0 && _item$manufacturer.full_name ? (_item$manufacturer2 = item.manufacturer) === null || _item$manufacturer2 === void 0 ? void 0 : _item$manufacturer2.full_name : (_item$manufacturer3 = item.manufacturer) !== null && _item$manufacturer3 !== void 0 && _item$manufacturer3.name ? (_item$manufacturer4 = item.manufacturer) === null || _item$manufacturer4 === void 0 ? void 0 : _item$manufacturer4.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr text-left"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_item$manufacturer5 = item.manufacturer) !== null && _item$manufacturer5 !== void 0 && _item$manufacturer5.inn ? item.manufacturer.inn : '–') + "/ " + _vm._s((_item$manufacturer6 = item.manufacturer) !== null && _item$manufacturer6 !== void 0 && _item$manufacturer6.kpp ? item.manufacturer.kpp : '–') + " ")])])];
      }
    }])
  }), _c('custom-navbar', {
    attrs: {
      "items": _vm.selected,
      "actions": _vm.navbar_actions
    },
    on: {
      "write_off": _vm.retireMarks
    },
    scopedSlots: _vm._u([{
      key: "button_unbind",
      fn: function () {
        var _vm$selected, _vm$selected$;

        return [_vm._v(" " + _vm._s((_vm$selected = _vm.selected) !== null && _vm$selected !== void 0 && (_vm$selected$ = _vm$selected[0]) !== null && _vm$selected$ !== void 0 && _vm$selected$.hide ? 'Привязать' : 'Отвязать') + " ")];
      },
      proxy: true
    }])
  }), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "crptech_diag_table"
    },
    on: {
      "change_pagination": _vm.changePagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }