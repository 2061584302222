var render = function render(){
  var _vm$search;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-block"
  }, [_c('b-form', {
    staticClass: "pos-r",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerSearch.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "search-block__img",
    attrs: {
      "src": "/img/icons/contract/search.svg",
      "alt": ""
    }
  }), _c('b-form-input', {
    staticClass: "search-block__input",
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), ((_vm$search = _vm.search) === null || _vm$search === void 0 ? void 0 : _vm$search.length) > 0 ? _c('b-button', {
    staticClass: "search-block__btn-clear-search-str",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }