var render = function render(){
  var _vm$item2, _vm$item2$serial, _vm$item3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "mark-modal-in"
    }
  }, [_c('b-modal', {
    staticClass: "mt-0",
    attrs: {
      "id": "mark-modal",
      "centered": "",
      "size": "lg",
      "hide-footer": "",
      "scrollable": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var _vm$item;

        var close = _ref.close;
        return [_c('h5', [_vm._v("Остаток | " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.name))]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm._v("Всего: " + _vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$serial = _vm$item2.serial) === null || _vm$item2$serial === void 0 ? void 0 : _vm$item2$serial.length))]), _c('resizable-table', {
    attrs: {
      "table_name": "mark-modal-table",
      "empty_text": "Нет марок",
      "items": (_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.serial,
      "default_fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "body_number",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "body_produced_date",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.produced_date ? _vm.formatDate(item.produced_date) : '--') + " ")];
      }
    }, {
      key: "body_introduced_date",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.introduced_date ? _vm.formatDate(item.introduced_date) : '--') + " ")];
      }
    }, {
      key: "body_mark",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-inline-flex",
          staticStyle: {
            "gap": "12px"
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/mark_qr.svg"
          }
        }), _c('img', {
          attrs: {
            "src": "/img/icons/copy.svg"
          },
          on: {
            "click": function ($event) {
              return _vm.copyMark(item);
            }
          }
        })])];
      }
    }, {
      key: "body_status",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "status-field",
          class: item.status
        }, [_vm._v(" " + _vm._s(item.status === 'accepted' ? 'В обороте' : 'Выведено из оборота') + " ")])];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }