<template>
  <div
    :id="product?.id + 'binding-btn'"
    class="binding-btn"
    title=""
    @click="click"
  >
    <img
      :src="icon"
      alt=""
    />
    <div class="binding-tooltip">
      <b-tooltip
        :target="product?.id + 'binding-btn'"
        class="binding-tooltip"
        placement="top"
        custom-class="product-info-tooltip"
      >
        <div v-if="this.product?.hide">Свяжите товар внешней системы</div>
        <div v-else>
          <div>
            {{ this.original_product?.name }}
          </div>
          <span class="product-info">{{ info }}</span>
        </div>
      </b-tooltip>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import { IncomingSpecificationModel } from '@/models/incoming-spec.model'
  import { ProductModel } from '@/models/product.model'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'

  export default {
    name: 'BindingChainBtn',
    props: {
      spec: IncomingSpecificationModel,

      original_product: ProductModel,

      product: ProductModel,

      type: {
        type: String,
        default: ''
      },

      supplier_name: {
        type: IncomingHeadModel,
        default: () => {}
      }
    },
    computed: {
      icon() {
        return this.product?.hide === false ? '/img/icons/binding-ok.svg' : '/img/icons/binding-no.svg'
      },
      info() {
        const product = this.original_product
        let str = ''
        if (product?.productExternalId?.[0]?.egaisParams?.type)
          str += 'Вид: ' + product?.productExternalId?.[0]?.egaisParams?.type + ' '
        if (product?.productExternalId?.[0]?.egaisParams?.strength)
          str += 'крепкость: ' + product?.productExternalId?.[0]?.egaisParams?.strength + ' '
        if (product?.productExternalId?.[0]?.egaisParams?.volume)
          str += 'крепкость: ' + product?.productExternalId?.[0]?.egaisParams?.volume + ' '
        if (product?.ext_system?.other?.[0]?.code) str += product?.ext_system?.other?.[0]?.code
        return str
      }
    },
    methods: {
      ...mapActions({
        setBindingModal: 'core/setBindingModal'
      }),
      click() {
        // this.setBindingModal({
        //   product: this.spec?.original_product || this.spec?.product?.original_product,
        //   target_product: this.spec?.product,
        //   spec_id: this.spec?.id,
        //   supplier_name: this.supplier_name
        // })
        this.setBindingModal({
          product: this.original_product,
          type: this.type,
          target_product: this.product,
          spec_id: this.spec?.id,
          supplier_name: this.supplier_name
        })
        this.$bvModal.show('core-binding-modal')
      }
    }
  }
</script>
<style scoped lang="scss">
  // .product-info-tooltip {
  //   .product-info {
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 12px;
  //     line-height: 24px;
  //     color: #bcbcbc;
  //     span {
  //       margin-right: 10px;
  //     }
  //   }
  //   .tooltip-inner {
  //     max-width: 400px;
  //     text-align: left;
  //   }
  // }
</style>

<style scoped lang="scss">
  .product-info-tooltip {
    ::v-deep() {
      .product-info {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #bcbcbc;
        span {
          margin-right: 10px;
        }
      }
      .tooltip-inner {
        max-width: 400px;
        text-align: left;
      }
    }
  }

  .binding-btn {
    cursor: pointer;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 16px;
      width: 16px;
    }
    &:hover {
      background: #efefef;
    }
  }
</style>
