<template>
  <section id="crptech-tab">
    <mark-modal :item="itemToShow" />
    <div class="header mt-3 mb-3">
      <div class="header__controls">
        <i-select v-model="production">
          <i-option value="null">Все типы продукции</i-option>
          <i-option
            v-for="(item, index) of markingTypeList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </i-option>
        </i-select>

        <i-select v-model="remainders">
          <i-option value="null">Все остатки</i-option>
          <i-option
            v-for="(item, index) of remaindersList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </i-option>
        </i-select>

        <i-select v-model="statuses">
          <i-option value="null">Все статусы</i-option>
          <i-option
            v-for="(item, index) of statusesList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </i-option>
        </i-select>
      </div>

      <div class="header__right">
        <span> Дата актуальности: {{ get_date() }} </span>
        <b-button
          variant="primary"
          @click="refreshData"
        >
          <img
            class="mr-2"
            src="/img/icons/refresh.svg"
          />
          Обновить
        </b-button>
      </div>
    </div>

    <crp-tech-tab-head @handler_search="handlerSearch" />

    <resizable-table
      table_name="crptech-diagnostics"
      :default_fields="fields"
      :items="items"
      style="flex: 1"
    >
      <template #head_id>
        <b-checkbox />
      </template>

      <template #head_hide>
        <img src="/img/icons/connection.svg" />
      </template>

      <template #body_id="{ item }">
        <b-checkbox
          v-model="selected"
          :value="item.id"
        />
      </template>

      <template #body_hide="{ item }">
        <binding-chain-btn
          :spec="{}"
          type="crp-tech"
          :original_product="item?.original_product"
          :product="item"
          @click="showBindingModal(item)"
        />
      </template>

      <!--      <template #body_product="{ item }">-->
      <!--        <div class="d-flex justify-content-between align-items-center">-->
      <!--          <div class="text-left" style="width: 85%">-->
      <!--            {{ item.name }}-->
      <!--            <div class="b-name article text-left">-->
      <!--              {{ item.articul || '&#45;&#45;' }}-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->
      <template #body_product="data">
        <div class="text-left">
          <!-- <img
            v-if="data.item.hide"
            v-b-tooltip.hover.top
            src="/img/icons/docs/warning.svg"
            class="mr-2 cursor"
            title="Обнаружен новый товар. Создайте товарную карточку или привяжите к существующей"
            @click="showBindingModal(data.item)"
          /> -->
          {{ data.item.name }}
          <div class="b-name article">
            <!-- <template v-if="data.item.hide">
              <span
                style="text-decoration: underline; color: #00a3ff"
                class="cursor"
                @click="showBindingModal(data.item)"
              >
                Привязать к товару
              </span>
            </template> -->
            <template>
              <div class="b-name article text-left">
                {{ data.item.articul || '--' }}
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #body_ext_name="data">
        <div class="text-left">
          {{ data.item.ext_product?.name }}
          <div class="b-name article">
            <div class="b-name article text-left">
              {{ data.item.ext_product?.code || '--' }}
            </div>
          </div>
        </div>
      </template>

      <template #body_total="{ item }">
        <div class="d-inline-flex align-items-center">
          <span> {{ item.marks - item.unhadled_marks || 0 }}</span>
          <img
            src="/img/icons/docs/marking.svg"
            class="ml-2"
            @click="showMarkModal(item)"
          />
        </div>
      </template>
      <template #body_crptech="{ item }">
        <div class="d-inline-flex align-items-center">
          <span>{{ item.marks || 0 }}</span>
          <img
            src="/img/icons/docs/marking.svg"
            class="ml-2"
            @click="showMarkModal(item)"
          />
        </div>
      </template>

      <template #body_manufacturer="{ item }">
        <div class="b-supplier name cursor text-left">
          {{
            item.manufacturer?.full_name
              ? item.manufacturer?.full_name
              : item.manufacturer?.name
              ? item.manufacturer?.name
              : 'Не указан'
          }}
        </div>
        <div class="b-supplier info table-substr text-left">
          ИНН/КПП:
          <span class="ml-2">
            {{ item.manufacturer?.inn ? item.manufacturer.inn : '–' }}/
            {{ item.manufacturer?.kpp ? item.manufacturer.kpp : '–' }}
          </span>
        </div>
      </template>
    </resizable-table>
    <custom-navbar
      :items="selected"
      :actions="navbar_actions"
      @write_off="retireMarks"
    >
      <template #button_unbind>
        {{ selected?.[0]?.hide ? 'Привязать' : 'Отвязать' }}
      </template>
    </custom-navbar>
    <pagination
      :total="total"
      table_name="crptech_diag_table"
      @change_pagination="changePagination"
    />
  </section>
</template>

<script>
  import { EventBus } from '@/main'
  import CrpTechTabHead from '../components/CrpTechTabHead.vue'
  import ResizableTable from '@/components/ResizableTable.vue'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import { FieldsModel } from '@/models/fields.model'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar.vue'
  import MarkModal from '@/views/diagnostics/components/MarkModal.vue'
  import { createDetached, getCert } from '@/utils/crptech/crptech_token'
  import { mapGetters } from 'vuex'
  import { ProductModel } from '@/models/product.model'
  import BindingChainBtn from '@/views/operational-processes/components/incoming/binding/BindingChainBtn.vue'

  export default {
    name: 'CrpTechTab',
    components: { MarkModal, CustomNavbar, Pagination, ResizableTable, CrpTechTabHead, BindingChainBtn },

    apollo: {
      MarkedProducts: {
        query: require('../gql/crp_tech/MarkedProducts.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              hide: this.getStatuses,
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take,
                search: this.search
              },
              unhandled_marks: this.getRemainders,
              markingType: this.production === 'null' ? null : this.production
            }
          }
        },
        result({ data }) {
          if (!data?.MarkedProducts) return
          this.items = data.MarkedProducts.products
          this.total = data.MarkedProducts.total
          this.refreshDate = new Date()
        }
      },

      MarkingTypes: {
        query: require('../../../views/products/gql/getMarkingTypes.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.markingTypeList = data.MarkingTypes
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        production: 'null',
        remainders: 'null',
        remaindersList: [
          { name: 'Остатки с расхождением', value: 'true' },
          { name: 'Остатки по учёту', value: 'false' }
        ],
        statuses: 'null',
        statusesList: [
          { name: 'Только связанные', value: 'true' },
          { name: 'Только несвязанные', value: 'false' }
        ],
        refreshDate: new Date(),
        markingTypeList: [],
        items: [],
        selected: [],
        total: 0,
        fields: [
          {
            key: 'id',
            label: '',
            width: 45,
            checked: true
          },
          {
            key: 'order',
            label: '№',
            width: 45,
            checked: true
          },
          {
            key: 'hide',
            label: '',
            width: 45,
            checked: true
          },
          {
            key: 'product',
            label: 'Наименование',
            width: 220,
            checked: true
          },
          {
            key: 'remainders',
            label: 'Остаток',
            width: 160,
            checked: true,
            children: [
              {
                key: 'total',
                label: 'По учету',
                width: 80,
                checked: true
              },
              {
                key: 'crptech',
                label: 'Честный знак',
                width: 80,
                checked: true
              }
            ]
          },
          {
            key: 'manufacturer',
            label: 'Производитель',
            width: 210,
            checked: true
          },
          {
            key: 'ext_name',
            label: 'Наименование ЧЗ',
            width: 210,
            checked: true
          }
        ].map((el) => new FieldsModel(el)),
        navbar_actions: [
          {
            id: 'write_off',
            label: 'Списать'
          },
          {
            id: 'unbind',
            label: 'Отвязать',
            length: 1
          }
        ],
        pagination: {
          skip: 0,
          take: 20
        },
        search: '',
        itemToShow: null
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      getRemainders() {
        if (this.remainders === 'null') return null
        if (this.remainders === 'true') return true
        else return false
      },

      getStatuses() {
        if (this.statuses === 'null') return null
        if (this.statuses === 'true') return true
        else return false
      }
    },

    created() {
      EventBus.$on('refetchMarkedProducts', () => {
        this.refreshData()
      })
    },

    beforeDestroy() {
      this.setBindingModal({})
    },

    destroyed() {
      EventBus.$off('refetchMarkedProducts', () => this.refreshData())
    },

    methods: {
      get_date() {
        return this.refreshDate.toLocaleString('ru-RU')
      },

      handlerSearch(search) {
        this.search = search
      },

      getTotalRemainders(item) {
        let total = 0
        if (item?.productType?.id === '52a4eab0-55c2-49f1-a152-f02cbc61e0b8')
          // УСЛУГА
          return '-'
        if (!item?.remainders) return 0

        item?.remainders?.forEach((i) => {
          total = total + i.value
        })

        return this.formatNumber(total)
      },
      refreshData() {
        this.pagination.skip = 0
        this.$apollo.queries.MarkedProducts.refresh()
      },
      changePagination(pagination) {
        this.pagination = {
          skip: (pagination.current_page - 1) * pagination.take,
          take: pagination.take
        }
      },
      async showMarkModal(item) {
        const { data } = await this.$apollo.query({
          query: require('../gql/crp_tech/getProductSerial.gql'),
          variables: {
            id: item.id,
            branch: this.currentBranch.id
          }
        })
        this.itemToShow = data.Product
        this.$bvModal.show('mark-modal')
      },
      async retireMarks() {
        const marks = []
        const products = this.items.filter((el) => this.selected?.includes(el.id))

        for (let sel of products) {
          marks.push(...sel.serial.map((el) => el.serial_number))
        }

        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/crp_tech/PrepareRetireMarks.graphql'),
          variables: {
            input: {
              cis: marks
            }
          }
        })

        if (data?.PrepareRetireMarks) {
          let cert = await getCert(data.PrepareRetireMarks.sign)

          const signed = await createDetached(cert, data?.PrepareRetireMarks.json)

          await this.$apollo.mutate({
            mutation: require('../gql/crp_tech/RetireMarks.graphql'),
            variables: {
              input: {
                json: data?.PrepareRetireMarks.json,
                sign: signed
              }
            }
          })

          this.selected = []
          await this.$apollo.queries.MarkedProducts.refetch()
        }
      },
      showBindingModal(item) {
        this.selectedBindingProduct = new ProductModel(item)
        this.$bvModal.show('product-binding-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .dropdown-menu {
    // height: 150px;
    overflow: scroll;
  }

  :deep .btn-light {
    background-color: transparent;
  }

  :deep .btn-light.dropdown-toggle {
    background-color: transparent;
  }

  :deep .btn-light:not(:disabled):not(.disabled):active {
    background-color: transparent;
  }

  #crptech-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .header__controls {
      display: inline-flex;
      gap: 16px;
    }

    .header__right {
      display: inline-flex;
      align-items: center;
      gap: 16px;
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-substr {
      font-size: 12px;
      color: #888888;
    }
  }
</style>
