<template>
  <section id="mark-modal-in">
    <b-modal
      id="mark-modal"
      centered
      size="lg"
      hide-footer
      class="mt-0"
      scrollable
    >
      <template #modal-header="{ close }">
        <h5>Остаток | {{ item?.name }}</h5>
        <b-button
          class="close"
          @click="close()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>
      <div class="mb-4">Всего: {{ item?.serial?.length }}</div>
      <resizable-table
        table_name="mark-modal-table"
        empty_text="Нет марок"
        :items="item?.serial"
        :default_fields="fields"
      >
        <template #body_number="{ index }">
          {{ index + 1 }}
        </template>

        <template #body_produced_date="{ item }">
          {{ item?.produced_date ? formatDate(item.produced_date) : '--' }}
        </template>

        <template #body_introduced_date="{ item }">
          {{ item?.introduced_date ? formatDate(item.introduced_date) : '--' }}
        </template>

        <template #body_mark="{ item }">
          <div
            class="d-inline-flex"
            style="gap: 12px"
          >
            <img src="/img/icons/mark_qr.svg" />
            <img
              src="/img/icons/copy.svg"
              @click="copyMark(item)"
            />
          </div>
        </template>

        <template #body_status="{ item }">
          <div
            class="status-field"
            :class="item.status"
          >
            {{ item.status === 'accepted' ? 'В обороте' : 'Выведено из оборота' }}
          </div>
        </template>
      </resizable-table>
    </b-modal>
  </section>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable.vue'
  import { FieldsModel } from '@/models/fields.model'
  import formatDate from '../../../utils/formatDate'

  export default {
    name: 'MarkModal',
    components: { ResizableTable },
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        fields: [
          {
            key: 'number',
            label: '№',
            width: 50,
            checked: true
          },
          {
            key: 'serial_number',
            label: 'Номер марки',
            width: 160,
            checked: true
          },
          {
            key: 'produced_date',
            label: 'Выпуск',
            width: 140,
            checked: true
          },
          {
            key: 'introduced_date',
            label: 'Ввод',
            width: 140,
            checked: true
          },
          {
            key: 'mark',
            label: 'Марка',
            width: 120,
            checked: true
          },
          {
            key: 'status',
            label: 'Статус',
            width: 160,
            checked: true
          }
        ].map((el) => new FieldsModel(el))
      }
    },
    methods: {
      formatDate(date) {
        return formatDate(new Date(date))
      },
      async copyMark({ serial_number }) {
        await navigator.clipboard.writeText(serial_number)
        this.$noty.show('Марка скопирована')
      }
    }
  }
</script>

<style scoped>
  .status-field {
    padding: 5px 0px;
    border-radius: 3px;
    text-align: left;
  }

  .accepted {
    color: #00cb91;
    background: #ebf9f5;
  }

  .released {
    color: #e53835;
    background: #ffebee;
  }
</style>
