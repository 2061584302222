<template>
  <div class="search-block">
    <b-form
      class="pos-r"
      @submit.prevent="handlerSearch"
    >
      <img
        class="search-block__img"
        src="/img/icons/contract/search.svg"
        alt=""
      />

      <b-form-input
        v-model="search"
        type="text"
        class="search-block__input"
        placeholder="Поиск"
      />
      <b-button
        v-if="search?.length > 0"
        class="search-block__btn-clear-search-str"
        @click.prevent="clearSearch"
      />
    </b-form>

    <b-dropdown
      class="ml-3"
      no-caret
      variant="none"
    >
      <template #button-content>
        <div class="btn-more">
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </div>
      </template>
      <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  export default {
    name: 'CrpTechTabHead',

    data() {
      return {
        search: ''
      }
    },
    methods: {
      handlerSearch() {
        this.$emit('handler_search', this.search)
      },

      clearSearch() {
        this.search = ''
      },

      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  .search-block {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    border-radius: 2px;
    border-bottom: none;

    &__img {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 10px;
    }

    &__input {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      width: 300px;
      height: 32px;
      padding-left: 28px;
    }
    &__input::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
</style>
